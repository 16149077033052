import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I finally decided to rebuild my site today. I wanted something that was low-key and unopinionated, so that I could more easily keep it up to date and also showcase bits of design that I've been working on.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      